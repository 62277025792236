@import '@/styles/_mixins'
@import '@/components/Button/Button.module.sass'

.feature
  align-items: center
  display: flex
  flex-direction: column
  gap: 50px
  justify-content: center
  margin-top: 100px

  @include breakpoints('sm')
    flex-direction: row

  + section
    padding-top: 0

  .button
    @include button

.imageWrapper
  position: relative
  width: 100%

  @include breakpoints('sm')
    max-width: 400px
    width: 50%

  &::before
    align-self: flex-start
    content: 'LATEST ISSUE'
    font-size: 10px
    font-weight: 700
    left: 40px
    position: absolute
    top: -12px

.details
  display: flex
  flex-direction: column

  span
    display: none

    @include breakpoints('sm')
      display: block
      font-size: 70px
      font-weight: 700
      margin-bottom: 40px
      text-align: left
      text-transform: uppercase

      em
        display: block
        font-size: 20px

.buttons
  align-items: flex-start
  display: flex
  gap: 20px

  @include breakpoints('sm')
    flex-direction: column
    justify-content: space-evenly

  a
    text-transform: uppercase
    width: max-content
