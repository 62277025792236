@import '@/styles/_mixins'

.container
  margin: 0 auto 100px
  max-width: 100%
  overflow-x: hidden
  padding: 0 12px
  text-align: center
  width: 100%

  @include breakpoints('sm')
    max-width: 100%
    padding: 0 20px

  @include breakpoints('lg')
    width: 95vw
