@import '@/styles/_mixins'

.header
  background-color: #fff
  box-shadow: 1px 1px 10px rgb(0 0 0 / 5%)
  height: 65px
  left: 0
  margin: 0
  padding: 0
  position: fixed
  right: 0
  top: 0
  transition: position 0.25s ease 0.75s
  z-index: 110000

  &.headerOpen
    position: fixed

    h1
      filter: invert(1)

    span
      background-color: #fff

  & > div
    align-items: center
    display: flex
    justify-content: space-between

  h1
    background-image: url('/mini-cake-logo.svg')
    background-position: center
    background-repeat: no-repeat
    background-size: 100px
    color: #fff
    cursor: pointer
    display: flex
    filter: contrast(1)
    font-size: 0
    height: 65px
    margin: 0
    max-width: 100%
    padding: 0
    text-indent: -200vw
    width: 140px
    z-index: 3

.shadow
  margin-bottom: -100px

  img
    height: 50px

  nav
    display: none

.burgerIcon
  cursor: pointer
  height: 25px
  margin: 10px 0 0
  position: relative
  text-indent: -200vw
  transition: filter 0.25s ease
  width: 25px
  z-index: 3

  &:hover
    @include breakpoints('lg')
      filter: blur(1px)

  span
    background: #000
    border-radius: 9px
    display: block
    height: 2px
    left: 0
    opacity: 1
    position: absolute
    transform: rotate(0deg)
    transition: all 0.25s ease-in-out
    width: 100%

    &:nth-child(1)
      top: 0px

    &:nth-child(2),
    &:nth-child(3)
      top: 6px

    &:nth-child(4)
      top: 12px

  &.open
    transition: opacity 0.5s ease

    &:hover
      opacity: 0.5

    span
      &:nth-child(1)
        left: 50%
        opacity: 0
        top: 6px
        width: 0%

      &:nth-child(2)
        transform: rotate(45deg)

      &:nth-child(3)
        transform: rotate(-45deg)

      &:nth-child(4)
        left: 50%
        opacity: 0
        top: 6px
        width: 0%

.menu
  background: transparent
  display: flex
  flex-direction: column
  height: 100vh
  justify-content: center
  left: -100vw
  position: absolute
  text-transform: uppercase
  top: 0
  transition: all 0.5s ease
  width: 100vw
  z-index: 2

  &.menuOpen
    background: rgba(0, 0, 0, .97)
    left: 0
    padding-right: 30px
    transition: left .5s ease, opacity 0.5s ease 0.5s

    ul
      opacity: 1
      top: 0

  input
    color: #fff

  ul
    list-style: none
    opacity: 0
    position: relative
    transition: opacity 0.25s ease-in-out 0.5s

  li
    border-bottom: 1px solid #0f0f0f
    margin-top: 12px
    padding: 8px 0
    text-align: right

    @include breakpoints('lg')
      border: none
      margin: 0 0 20px
      padding: 0
      text-align: center

    &.search
      border: none

    &.institutionalLinks
      a
        font-size: 12px
        padding: 0

        &:last-child
          margin-right: 10px

        &:not(:last-child)::after
          content: ' | '
          margin: 0 10px

        @include breakpoints('sm')
          font-size: 14px

        @include breakpoints('lg')
          font-size: 16px
          margin: 0 10px 0 0

          &:not(:last-child)::after
            content: ''

          &:hover
            @include breakpoints('lg')
              filter: sepia(1) blur(2px)
              text-decoration: line-through

    &.socialIcons
      border: none
      display: flex
      flex-wrap: wrap
      gap: 10px
      margin-top: 0
      justify-content: flex-end

      @include breakpoints('sm')
        margin-top: 24px

      @include breakpoints('lg')
        justify-content: center
        margin: 0 0 0 45px

      &::before
        @include breakpoints('sm')
          color: #8a8a8a
          content: 'YOU CAN ALSO FIND US ON'
          display: block
          font-size: 10px
          font-weight: 400
          margin-bottom: 10px
          width: 100%

        @include breakpoints('lg')
          margin-right: 0

      &::after
        color: #666
        content: '© 2022 Cake Magazine – All rights reserved'
        flex-basis: 100%
        font-size: 10px
        text-transform: none

      a
        color: #fff
        @include breakpoints('lg')
          margin: -20px 10px 0 0
          padding: 0 6px

          &:last-of-type
            margin-right: 0

    a
      color: #fff
      font-size: 16px
      font-weight: 700
      margin-top: 14px
      padding: 3px 12px
      text-decoration: none
      transition: all 0.5s ease-in-out

      @include breakpoints('lg')
        border: none
        font-size: 36px
        margin-top: 5px

      &:only-child:hover
        @include breakpoints('lg')
          filter: sepia(1) blur(2px)
          text-decoration: line-through

    img
      filter: invert(1)
      height: 27px
      opacity: 1
      transition: opacity 0.5s ease

      &:hover
        opacity: 0.25

      @include breakpoints('lg')
        height: 20px
