@import '@/styles/_mixins'

.searchContainer
  align-items: center
  border-bottom: 1px solid rgba(230, 230, 230, 1)
  margin: 60px auto 0
  padding: 0 10px 4px 0

  @include breakpoints('sm')
    max-width: 50vw

  @include breakpoints('md')
    max-width: 30vw
    padding: 14px

  @include breakpoints('lg')
    padding: 4px 12px

  form
    display: flex

  button
    background: transparent
    border: none
    border-bottom-right-radius: 20px
    border-top-right-radius: 20px
    cursor: pointer
    height: auto
    margin: -14px
    padding: 12px

    @include breakpoints('lg')
      margin: -4px -12px
      padding: 2px 8px

  input
    background: transparent
    border: none
    color: #000
    font-size: 14px
    font-style: italic
    font-weight: 100
    outline: none
    width: 100%

    @include breakpoints('md')
      font-size: 32px

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration
      display: none
