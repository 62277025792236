
@mixin breakpoints($breakpoint)
  $breakpoints: ("sm": (min-width: 600px), "md": (min-width: 900px), "lg": (min-width: 1200px), "xl": (min-width: 1500px))
  $raw-query: map-get($breakpoints, $breakpoint)

  @if $raw-query
    $query: if(type-of($raw-query) == 'string', unquote($raw-query), inspect($raw-query))

    @media #{$query}
      @content
  @else
    @error 'No value found for `#{$breakpoint}`. Please make sure it is defined in `$breakpoints` map.'

@mixin post-list
  background-color: #fff
  padding-top: 80px
  position: relative

  & > div
    display: flex
    flex-wrap: wrap
    justify-content: space-evenly


    & > article, & > div
      flex-basis: 100%

      @include breakpoints('sm')
        flex-basis: 50%

      @include breakpoints('md')
        flex-basis: 45%

      @include breakpoints('lg')
        flex-basis: 30%
