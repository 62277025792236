@import '@/styles/_mixins'

.postItem
  min-height: 100%
  overflow: hidden
  position: relative
  transition: opacity 0.5s ease, transform 0.25s ease

  &:hover
    @include breakpoints('md')
      transform: scale(1.3)

      .image
        filter: grayscale(1)
        mix-blend-mode: hard-light
        opacity: 0.25

      .overlay
        opacity: 1

      span
        margin-left: 0

.image
  opacity: 1
  transition: opacity 0.75s ease, filter 0.25s ease 0.25s

.overlay
  display: inline-block
  height: 100%
  font-size: 32px
  font-weight: 100
  justify-content: flex-end
  overflow: hidden
  transition: opacity 0.5s ease
  vertical-align: middle
  width: auto
  z-index: 1

  @include breakpoints('md')
    opacity: 1

  &:hover
    opacity: 1

  span
    display: none

    @include breakpoints('md')
      background: linear-gradient(to bottom, #fff000 50%, transparent 50%)
      color: #767676
      display: block
      font-size: 12px
      font-weight: 400
      height: 65px
      left: 0
      line-height: 16px
      margin-left: -100vw
      max-width: 75%
      padding: 10px 24px
      position: absolute
      text-align: left
      text-transform: uppercase
      top: 50%
      transition: margin-left 0.5s ease, opacity 0.5s ease-in 0.5s
      white-space: normal

    @inclde breakpoints('lg')
      max-width: 60%

    &::first-line
      @include breakpoints('md')
        color: #000
        font-size: 44px
        font-style: normal
        font-weight: 800
        line-height: 40px
        text-transform: uppercase
      @include breakpoints('lg')
        font-size: 32px
        letter-spacing: -1px
      @include breakpoints('xl')
        font-size: 42px
        word-spacing: 8px


.fallbackImage
  margin-top: 50px
