@mixin button
  background: yellow
  border: 0
  color: #000
  cursor: pointer
  display: inline-block
  font-weight: 700
  padding: 10px 20px
  transition: transform 0.25s ease

  &:hover
    transform: scale(1.5)

.button
  @include button
